<template>
  <div class="contact page">
    <ContactSplash/>
    <ContactForm/>
    <ContactFounders/>
    <NavFooter/>
  </div>
</template>

<script>
import ContactSplash from '../components/ContactSplash'
import ContactForm from '../components/ContactForm'
import ContactFounders from '../components/ContactFounders'
import NavFooter from '../components/NavFooter'

export default {
  name: 'Contact',
  components: {
    ContactSplash,
    ContactForm,
    ContactFounders,
    NavFooter
  }
}
</script>

<style lang="scss" scoped>

</style>
