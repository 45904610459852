<template>
  <div class="contact-form up-1" v-scrollanimate>
    <h4 class="h3 t-0">Contact</h4>
    <div class="desc">Have a question or interested in partnering up on a project?<br><br><a class="link eff-base-r eff-1 eff-b" :href="prefix+decoded">{{ decoded }}</a></div>
    <hr class="line">
  </div>
</template>

<script>
export default {
  name: 'ContactForm',
  data () {
    return {
      homePage: false,
      decoded: atob('aGVsbG9Acm9hZGh1Yi5kZXNpZ24='),
      prefix: atob('bWFpbHRvOg==')
    }
  }
}
</script>

<style lang="scss" scoped>
.contact-form {
  @include grid;
  margin-top: 48px;
  @include phone {
    margin-top: 26px;
  }
  .t-0 {
    grid-column: 2 / 8;
    grid-row: 1 / 2;
    @include tablet {
      margin-bottom: 32px;
    }
    @include phone {
      grid-column: 2 / 6;
      margin-bottom: 64px;
    }
  }
  .desc {
    grid-column: 8 / 13;
    grid-row: 1 / 2;
    margin-bottom: 128px;
    @include laptop {
      margin-bottom: 96px;
    }
    @include tablet {
      grid-column: 2 / 8;
      grid-row: 2 / 3;
    }
    @include phone {
      grid-column: 2 / 6;
      margin-bottom: 64px;
    }
  }
  .line {
    grid-column: 2 / 14;
    grid-row: 2 / 3;
    @include tablet {
      grid-column: 2 / 10;
      grid-row: 3 / 4;
    }
    @include phone {
      grid-column: 2 / 6;
    }
  }
}
.eff-base-r:after {
  bottom: -4px !important;
}
</style>
