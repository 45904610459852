<template>
  <div class="splash">
    <div class="viewport">
      <picture class="contact-image">
        <source srcset="~@/assets/contact.webp" type="image/webp">
        <source srcset="~@/assets/contact.jpg" type="image/jpeg">
        <img src="~@/assets/contact.jpg" alt="Land shortage"/>
      </picture>
    </div>
    <h2 class="h4 t-1">Thinking of working with us?</h2>
    <div class="p-1">We’re on a mission to make our patented design innovations available for cities everywhere. We’re available to help you implement them to best fit your unique circumstances.</div>
    <hr class="line">
  </div>
</template>

<script>
export default {
  name: 'ContactSplash'
}
</script>

<style lang="scss" scoped>
.splash {
  @include grid;
  .viewport {
    grid-column: 1 / 15;
    grid-row: 1 / 2;
    z-index: -2;
    @include tablet {
      grid-column: 1 / 11;
    }
    @include phone {
      grid-column: 1 / 7;
    }
    img {
      width: 100%;
      vertical-align: bottom;
      object-fit: cover;
      @include phone {
        height: 66.7vw;
      }
    }
  }
  .t-1 {
    grid-column: 8 / 13;
    grid-row: 2 / 3;
    margin-top: 128px;
    @include laptop {
      margin-top: 96px;
    }
    @include tablet {
      grid-column: 2 / 8;
      margin-top: 64px;
    }
    @include phone {
      grid-column: 2 / 6;
    }
  }
  .p-1 {
    grid-column: 8 / 13;
    grid-row: 3 / 4;
    margin-top: 54px;
    margin-bottom: 128px;
    @include laptop {
      margin-top: 32px;
      margin-bottom: 96px;
    }
    @include tablet {
      grid-column: 2 / 8;
    }
    @include phone {
      margin-top: 26px;
      margin-bottom: 64px;
      grid-column: 2 / 6;
    }
  }
  .line {
    grid-column: 2 / 14;
    grid-row: 4 / 5;
    @include tablet {
      grid-column: 2 / 10;
    }
    @include phone {
      grid-column: 2 / 6;
    }
  }
}

</style>
