<template>
  <div class="about">
    <h2 class="h3 t-1 up-1" v-scrollanimate>Team</h2>
    <div class="p-1 up-1" v-scrollanimate>
      <picture class="hero-image">
        <source srcset="~@/assets/gilead.webp" type="image/webp">
        <source srcset="~@/assets/gilead.png" type="image/png">
        <img src="~@/assets/gilead.png" alt="Gilead Meroz" />
      </picture>
      <h4 class="h4 t-1-1">Gilead Meroz</h4>
      <div class="sub h6">Co-founder & CTO</div>
      <div class="p-1-1">Gilead is fascinated with solving sustainability problems through clever design. Coming from the fields of architecture and furniture design, his expertise is in the design of multifunctional spaces, and he holds several registered designs and patents. His eventual acquaintance with permaculture led him to a life of experimentation with green technologies.<br><br>By activating his family, their urban house was transformed into a living laboratory of sustainable methods, where he develops innovative solutions for solid plastic waste, water treatment, composting, and desertification.<br><br>At RoadHub, Gilead is in charge of research, development, planning, and patenting.</div>
    </div>
    <div class="p-2 up-1" v-scrollanimate>
      <picture class="hero-image">
        <source srcset="~@/assets/yaron.webp" type="image/webp">
        <source srcset="~@/assets/yaron.png" type="image/png">
        <img src="~@/assets/yaron.png" alt="Yaron Toren" />
      </picture>
      <h4 class="h4 t-2-1">Yaron Toren</h4>
      <div class="sub h6">Co-Founder & COO</div>
      <div class="p-2-1">Yaron is passionate about making cities work for everyone, with a particular interest in transportation systems.<br><br>His educational background includes a Bachelor’s degree in industrial design (at the Tel-Aviv University) followed by a Master’s degree in public policy (at the Hebrew University). This unique combination reached its fruition with founding and developing RoadHub, where he oversees its business operations.<br><br>He has extensive work experience in the city of Jerusalem, where he develops and implements plans regarding employment, poverty, transportation, and the environment, and advocates for social causes and community cohesion.</div>
    </div>
    <div class="p-3 up-1" v-scrollanimate>
      <picture class="hero-image">
        <source srcset="~@/assets/hagai.webp" type="image/webp">
        <source srcset="~@/assets/hagai.png" type="image/png">
        <img src="~@/assets/hagai.png" alt="Hagai Gat" />
      </picture>
      <h4 class="h4 t-1-1">Hagai Gat</h4>
      <div class="sub h6">CEO</div>
      <div class="p-1-1">Hagai is a tech and infrastructure expert with great passion for long-term problem-solving — especially in the fields of water management, energy, and transportation. He holds a Bachelor degree in Political Science and East Asian Studies, and further trained in project finances and the Electric Vehicles eco-system.<br><br>Having lived in China and Ecuador, he worked with major technological and construction companies in multiple sectors of the infrastructure ecosystem, and speaks Mandarin and Spanish.<br><br>As RoadHub’s CEO, Hagai manages its business strategy and day-to-day activity, bringing his vast international experience in marketing, government relations, and technology.</div>
    </div>
    <div class="p-4 up-1" v-scrollanimate>
      <picture class="hero-image">
        <source srcset="~@/assets/daniella.webp" type="image/webp">
        <source srcset="~@/assets/daniella.png" type="image/png">
        <img src="~@/assets/daniella.png" alt="Daniella Segal" />
      </picture>
      <h4 class="h4 t-1-1">Daniella Segal</h4>
      <div class="sub h6">Adv. - Legal advisor</div>
      <div class="p-1-1">Daniella is currently Deputy General Counsel and Chief Compliance Officer at Assured Allies, developing solutions for successful aging and wellness.<br><br>In her previous roles, she was Head of Legal and Compliance at Rewire, which has developed a cross-border international banking platform for migrants, and she worked in one of Israel’s top-tier law firms, representing local and international corporations in the hi-tech, banking, finance, and bio-technology industries in a variety of complex multinational corporate transactions.<br><br>Daniella holds an LL.B. and B.A. in Law and Government Studies from IDC Herzliya and was a member of the Argov Fellows Program. She is RoadHub’s in-house legal advisor.</div>
    </div>
    <hr class="line">
  </div>
</template>

<script>
export default {
  name: 'ContactFounders'
}
</script>

<style lang="scss" scoped>
.about {
  @include grid;
  margin-top: 48px;
  @include phone {
    margin-top: 26px;
  }
  .t-1 {
    grid-column: 2 / 6;
    grid-row: 1 / 2;
  }
  .p-1 {
    grid-column: 2 / 7;
    grid-row: 2 / 3;
    @include tablet {
      grid-column: 2 / 8;
      grid-row: 2 / 3;
      margin-top: 26px;
      margin-bottom: 48px;
    }
    @include phone {
      margin-top: 26px;
      grid-column: 2 / 6;
    }
    .sub {
      margin-top: 10px;
      margin-bottom: 44px;
    }
  }
  .p-2 {
    grid-column: 8 / 13;
    grid-row: 2 / 3;
    margin-bottom: 48px;
    @include tablet {
      grid-column: 2 / 8;
      grid-row: 3 / 4;
      margin-top: 32px;
    }
    @include phone {
      grid-column: 2 / 6;
    }
    .sub {
      margin-top: 10px;
      margin-bottom: 44px;
    }
  }
  .p-3 {
    grid-column: 2 / 7;
    grid-row: 3 / 4;
    margin-bottom: 128px;
    @include laptop {
      margin-bottom: 96px;
    }
    @include tablet {
      grid-column: 2 / 8;
      grid-row: 4 / 5;
      margin-top: 32px;
    }
    @include phone {
      grid-column: 2 / 6;
    }
    .sub {
      margin-top: 10px;
      margin-bottom: 44px;
    }
  }
  .p-4 {
    grid-column: 8 / 13;
    grid-row: 3 / 4;
    margin-bottom: 128px;
    @include laptop {
      margin-bottom: 96px;
    }
    @include tablet {
      grid-column: 2 / 8;
      grid-row: 5 / 6;
      margin-top: 32px;
      margin-bottom: 64px;
    }
    @include phone {
      grid-column: 2 / 6;
    }
    .sub {
      margin-top: 10px;
      margin-bottom: 44px;
    }
  }
  .line {
    grid-column: 2 / 14;
    grid-row: 4 / 5;
    @include tablet {
      grid-column: 2 / 10;
      grid-row: 6 / 7;
    }
    @include phone {
      grid-column: 2 / 6;
    }
  }
}
img {
  width: 100%;
  vertical-align: bottom;
  margin-top: 76px;
  margin-bottom: 46px;
  @include laptop {
    margin-top: 54px;
    margin-bottom: 32px;
  }
  @include tablet {
    margin-top: 32px;
  }
  @include phone {
    height: 66.7vw;
    object-fit: cover;
  }
}

</style>
